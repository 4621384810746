<template>
  <div class="delete-vote-wrapper">
    <h5>Вы действительно хотите удалить это голосование?</h5>
    <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
    <footer>
      <b-button @click="submitForm" :disabled="isLoadingSaveForm" variant="danger">
        {{ isLoadingSaveForm ? 'Удаляем...' : 'Удалить' }}
      </b-button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "DeleteVoteModal",
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: null
    }
  },
  methods: {
    ...mapActions('moduleVoting', ['deleteVote']),
    async submitForm() {
      try {
        this.isLoadingSaveForm = true;
        this.errorText = null;
        await this.deleteVote(this.params.id);
        this.$emit('voteDeleted');
        this.$emit('closeModal');
        this.$toast.success('Голосование удалено', { position: 'top-right', timeout: 2000 });
      } catch (e) {
        this.errorText = e.response?.data?.message || 'Произошла ошибка при удалении голосования';
      } finally {
        this.isLoadingSaveForm = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.delete-vote-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }

  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
}
</style>