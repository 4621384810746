<template>
  <div class="actualization-btns">
    <b-button @click="$emit('closeModal')">Отменить</b-button>
    <b-button variant="success" @click="onConfirm">Подтвердить</b-button>
  </div>
</template>

<script>
export default {
  name: "ConfirmActualization",
  emits: ["closeModal", "submit"],
  methods: {
    async onConfirm() {
      try {
        await this.$emit("submit");
        this.$emit("closeModal"); // Закрыть модалку
      } catch (error) {
        console.error("Ошибка при подтверждении:", error);
      }
    },
  },
};
</script>

<style>

.actualization-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 0.5rem;
    margin-top: 1rem;
    button {
    width: 100%;
     }
  }
}

</style>