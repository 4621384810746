import {mapActions, mapGetters, mapMutations} from "vuex";
import * as mutypes from "/src/store/mutation.types";
import Vue from "vue";
export const TeamsAdapterMixin = {
    data() {
        return {
            membersArray: [],
            currentTeam:[],
            commandOwner: {},
            newTeamName: '',
            idModalForDelete: null,
            newRating: null,
            newDescription: null,
            selectedGameId: null,
            isLoadingTeams: false,
            searchForTeamsModel: '',
            teamsType: {
                '0': 'indexTeams',
                '2': 'trashedTeams',
            }
        }
    },
    computed: {
        ...mapGetters({
            getTeams: 'getTeams',
            getTeamsId: 'getTeamsId',
            getTeamsName: 'getTeamsName',
            getTeamsGameId: 'getTeamsGameId',
            getTeamsShow : 'getTeamsShow',
            getTeamsPaginationTotal : 'getTeamsPaginationTotal',
            getTeamsPaginationCurrentPage     : 'getTeamsPaginationCurrentPage',
            getGroupsForNextStage: 'getStandingTournamentGroupsForNextStage',
            getUserIdForChange: 'getUserIdForChange',
            getMembers : 'getMembers',
            getCurrentDivision : 'getCurrentDivision',
            getTeamCurrentRating: 'getTeamCurrentRating',
            getTeamLogs : 'getTeamLogs',
            getTeamLogsPaginationTotal : 'getTeamLogsPaginationTotal',
            getTeamLogsPaginationCurrentPage     : 'getTeamLogsPaginationCurrentPage',
            getCaptainLang : 'getCaptainLang',
            getGamesForTeams: 'getGamesForTeams',
            getDeletedTeams: 'getDeletedTeams',
            getDeletedTeamsPaginationTotal : 'getDeletedTeamsPaginationTotal',
            getDeletedTeamsPaginationCurrentPage     : 'getDeletedTeamsPaginationCurrentPage',
        }),

        teams: { get(){ return this.getTeams                }, set(value){this.setTeams(value)}},
        teamsId: { get(){ return this.getTeamsId            }, set(value){this.setTeamsId(value)}},
        teamsName: { get(){ return this.getTeamsName        }, set(value){this.setTeamsName(value)}},
        teamsGameId: { get(){ return this.getTeamsGameId    }, set(value){this.setTeamsGameId(value)}},
        teamsShow: {get(){ return this.getTeamsShow         }, set(value){this.setTeamsShow(value) }},
        total             : {get() {return this.getTeamsPaginationTotal             }, set(value) {this.setTeamsPaginationTotal(value)  }},
        currentPage       : {get() {return this.getTeamsPaginationCurrentPage             }, set(value) {this.setTeamsCurrentPage(value)  }},
        members: {get(){ return this.getMembers         }, set(value){this.setMembers(value) }},
        currentDivision: {get(){ return this.getCurrentDivision         }, set(value){this.setCurrentDivision(value) }},
        teamCurrentRating: {get(){ return this.getTeamCurrentRating       }, set(value){this.setTeamCurrentRating(value) }},
        teamLogs: {
            get() {
                return this.getTeamLogs
            }, set(value) {
                this.setTeamLogs(value)
            }
        },
        teamLogsTotal: {
            get() {
                return this.getTeamLogsPaginationTotal
            }, set(value) {
                this.setTeamLogsPaginationTotal(value)
            }
        },
        teamLogsCurrentPage: {
            get() {
                return this.getTeamLogsPaginationCurrentPage
            }, set(value) {
                this.setTeamLogsCurrentPage(value)
            }
        },
        captainLang: {
            get() {
                return this.getCaptainLang
            }, set(value) {
                this.setCaptainLang(value)
            }
        },
        games: {
            get() {
                return this.getGamesForTeams
            }, set(value) {
                this.setGamesForTeams(value)
            }
        },
        deletedTeams: {
            get() {
                return this.getDeletedTeams
            }, set(value) {
                this.setDeletedTeams(value)
            }
        },
        totalDeletedTeams: {
            get() {
                return this.getDeletedTeamsPaginationTotal
            }, set(value) {
                this.setDeletedTeamsPaginationTotal(value)
            }
        },
        deletedTeamsCurrentPage: {
            get() {
                return this.getDeletedTeamsPaginationCurrentPage
            }, set(value) {
                this.setDeletedTeamsCurrentPage(value)
            }
        },
    },

    methods: {
        ...mapMutations({
            setTeams        : mutypes.SET_TEAMS,
            setTeamsId      : mutypes.SET_TEAMS_ID,
            setTeamsName    : mutypes.SET_TEAMS_NAME,
            setTeamsGameId  : mutypes.SET_TEAMS_GAME_ID,
            setTeamsPaginationTotal    : mutypes.SET_TEAMS_PAGINATION_TOTAL,
            setTeamsCurrentPage    : mutypes.SET_TEAMS_PAGINATION_CURRENT_PAGE,
            setTeamsShow    : mutypes.SET_TEAMS_SHOW,
            setMembers    : mutypes.SET_TEAMS_MEMBERS,
            setTeamLogs    : mutypes.SET_TEAM_LOGS,
            setTeamLogsPaginationTotal    : mutypes.SET_TEAM_LOGS_PAGINATION_TOTAL,
            setTeamLogsCurrentPage    : mutypes.SET_TEAM_LOGS_PAGINATION_CURRENT_PAGE,
            setCurrentDivision    : mutypes.SET_TEAMS_CURRENT_DIVISION,
            setTeamCurrentRating    : mutypes.SET_TEAMS_TEAM_RATING,
            setCaptainLang    : mutypes.SET_TEAMS_CAPTAIN_LANG,
            setGamesForTeams: mutypes.SET_TEAMS_GAMES,
            setDeletedTeams: mutypes.SET_DELETED_TEAMS,
            setDeletedTeamsPaginationTotal: mutypes.SET_DELETED_TEAMS_PAGINATION_TOTAL,
            setDeletedTeamsCurrentPage: mutypes.SET_DELETED_TEAMS_PAGINATION_CURRENT_PAGE,
        }),
        ...mapActions([
            'indexTeams',
            'trashedTeams',
            'showTeams',
            'storeTeam',
            'changeMemberRole',
            'storeTeamMember',
            'updateTeamName',
            'showTeamRating',
            'updateTeamRating',
            'shortTeamRating',
            'addTeamRating',
            'teamLogsShow',
            'grabGamesForTeams',
        ]),
       async showTeamInfo(id){
            await this.showTeams(id).then(()=>{
               this.$router.push({name: 'teams_info', params: {id:id}})
            })
        },
        async getTeamsByGame(game) {
            this.isLoadingTeams = true;
            await this.indexTeams({
              gameId: game,
              search: this.searchForTeamsModel,
              divisionId: this.teamTypeFilter === 'pro' ? 5 : null,
            })
              .catch(() => {
                Vue.toasted.error("не удалось загрузить команды", {
                  position: 'top-center',
                  duration: 3000
                });
              })
              .finally(() => {
                this.selectedGameId = game || null;
                this.isLoadingTeams = false;
              });
        },
        async getTrashedTeamsByGame(game) {
            this.isLoadingTeams = true
            await this.trashedTeams({
                ...(game && {gameId: game}),
                ...(this.searchForTeamsModel && {search: this.searchForTeamsModel}),
            })
                .catch(() => {
                    Vue.toasted.error("не удалось загрузить удаленные команды", {
                        position: 'top-center',
                        duration: 3000
                    });
                })
                .finally(() => {
                    this.selectedGameId = game || null
                    this.isLoadingTeams = false
                })
        },
    }
}
