import { render, staticRenderFns } from "./GroupCard.vue?vue&type=template&id=3c78d0b7&scoped=true"
import script from "./GroupCard.vue?vue&type=script&lang=js"
export * from "./GroupCard.vue?vue&type=script&lang=js"
import style0 from "./GroupCard.vue?vue&type=style&index=0&id=3c78d0b7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c78d0b7",
  null
  
)

export default component.exports