<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="Все пользователи - рейтинг"/>
      <b-card>
        <div class="d-flex flex-wrap mb-4 justify-content-between">
          <div>
            <SearchInput v-model="searchForUsersRatingModel" @search="getFilteredUsersRating()" :placeholder-name="'Поиск пользователей'"/>
            <div class="notification-for-search">Поиск по id, username, почте, названию команды, игровым данным: pubg id pubg nick</div>
          </div>
          <div class="mt-2 mt-lg-0">
              <b-btn @click="downloadTopPlayers" :disabled="isLoadingDownloadTopPlayers" :variant="isLoadingDownloadTopPlayers ? 'primary' : 'info'">
                {{ isLoadingDownloadTopPlayers ? 'Скачиваем...' : 'Скачать топ 20 игроков' }}
              </b-btn>
          </div>
          <div class="mt-2 mt-lg-0">
            <div>
              <b-btn @click="submitForm" :disabled="isLoadingActualization" :variant="isLoadingActualization ? 'primary' : 'info'">
                {{ isLoadingActualization ? 'Идет актуализация...' : 'Актуализировать рейтинг игрока' }}
              </b-btn>
            </div>
            <div style="color: #666666" class="mt-2">Дата актуализации: {{ actualizedRatingTime || '' }}</div>
          </div>
        </div>
        <div v-if="isLoadingRatingForUsers" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <div v-else>
          <b-table
              responsive
              show-empty
              stacked="md"
              :striped="striped"
              :bordered="bordered"
              :fields="fields"
              :items="usersRating"
              :current-page=0
              :per-page=10
              class="mb-5"
          >
            <template v-slot:cell(username)="{item}">
              {{ item.username }}
            </template>
            <template v-slot:cell(pubgNick)="{item}">
              {{
                !item.gameProfiles.length ? 'нет игрового профиля' :
                    (item.gameProfiles.find((profile) => profile.game.slug === 'pubg_mobile')?.fields.nick || 'По данной игре nick не найден')
              }}
            </template>
            <template v-slot:cell(teams)="{item}">
              <div class="d-flex justify-content-center">
                <b-btn variant="info"
                       sm
                       v-b-toggle="'players'+ item.id"
                       class="px-3"
                >
                  <i class="fa fa-users"></i></b-btn>
              </div>
              <b-collapse :id="'players'+ item.id">
                <div class="mt-3">
                  <b-list-group v-for="team in item?.teams" :key="'teamId-' + team.id">
                    <b-list-group-item class="p-0 px-1 text-left">
                      {{ team.name }}
                    </b-list-group-item>

                  </b-list-group>
                </div>
              </b-collapse>
            </template>
            <!--            <template v-slot:cell(tournaments)="{item}">-->
            <!--            </template>-->
            <template v-slot:cell(rating)="{item}">
              {{ item.ratingPubgMobile.rating.toFixed(2) }}
            </template>
            <template v-slot:cell(action)="{item}">
              <div class="align-items-center">
                <router-link :to="{name:'user_ratings_info', params: {id: item.id }}">
                  <span><b-btn variant="primary" class="mr-2 p-1 px-2 mb-1">Посмотреть историю</b-btn></span>
                </router-link>
              </div>
            </template>
          </b-table>
          <b-col md="6" class="p-0">
            <b-pagination
                @change="handlePageChange"
                v-model="currentPage"
                :total-rows="total"
                :per-page="10"
                class="my-0"
            ></b-pagination>
          </b-col>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {UsersRatingAdapterMixin} from "@/mixins/Rating/UserRatingAdapterMixin";
import Vue from "vue";
import api from "@/api/api";
import SearchInput from "@/components/SearchInput.vue";
import moment from 'moment'

export default {
  name: "ListUsersRating",
  mixins: [PaginationHelperMixin, UsersRatingAdapterMixin],
  components: {SearchInput},
  created() {
    try {
      this.isLoadingRatingForUsers = true,
      (async () => {
        this.recordPageToStorage(this.currentPage, this.pageKey)
        this.grabUsersRating({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
        const actualizedUserRatingTime = api.usersRating.actualizedTime().then((response) => {
          this.actualizedRatingTime = response.data.data.lastActualizeDatetime || '';
        });

        const gameList = api.games.index().then((gameList) => {
          this.gameId = gameList.data.games.find((game) => game.slug === 'pubg_mobile').id;
        });

        await Promise.all([actualizedUserRatingTime, gameList])
      })()
    } finally {
      this.isLoadingRatingForUsers = false
    }
  },
  data() {
    return {
      pageKey: 'usersRatingPageNumber',
      fields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'username', label: 'Имя', sortable: true},
        {key: 'pubgNick', label: 'PUBG nick', sortable: true},
        {key: 'teams', label: 'Команды', sortable: true},
        // {key: 'tournaments', label: 'Кол-во турниров', sortable: true},
        {key: 'rating', label: 'Общ. рейтинг', sortable: true},
        {key: 'action', label: 'Действия', sortable: false},
      ],
      striped: true,
      bordered: true,
      isLoadingActualization: false,
      actualizedRatingTime: '',
      gameId: null,
      isLoadingRatingForUsers: false,
      searchForUsersRatingModel: '',
      isLoadingDownloadTopPlayers: false,
      excelFile: null
    };
  },
  methods: {
    async submitForm() {
      try {
        this.isLoadingActualization = true
        await api.usersRating.actualize()
        Vue.toasted.success("Успешно актуализирован", {
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
          Vue.toasted.error(error.response.data.message || "Не удалось актуализировать", {
            position: 'top-right',
            duration: 3000
          });
      } finally {
        this.isLoadingActualization = false
      }
    },
    async downloadTopPlayers() {
      try {
        this.isLoadingDownloadTopPlayers = true
        const createResponse = await api.excel.create();
        const fileId = createResponse.data.export.id;

        const downloadResponse = await api.excel.index(fileId, {
          method: 'GET',
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob'
        });
        // get current date
        let currentDate = new Date();
        let formattedDate = moment(currentDate).format('DD.MM.YYYY');

        const link = document.createElement('a');
        link.href = URL.createObjectURL(downloadResponse.data);
        link.setAttribute('download', `${formattedDate }_top20_players.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        Vue.toasted.success("Успешно скачано", {
          position: 'top-right',
          duration: 3000
        })
      } catch (error) {
          Vue.toasted.error(error.response.data.message || "Не удалось скачать", {
            position: 'top-right',
            duration: 3000
          });
      } finally {
          this.isLoadingDownloadTopPlayers = false
      }
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUsersRating({
        pageNumber: pageNumber,
        ...(this.searchForUsersRatingModel && {search: this.searchForUsersRatingModel}),
      })
    },
    getFilteredUsersRating() {
      this.isLoadingRatingForUsers = true
      this.grabUsersRating({search: this.searchForUsersRatingModel})
          .then(() => {
            this.isLoadingRatingForUsers = false
          })
    },
  },

};
</script>
