<template>
  <div>
    <b-btn
        v-bind="$attrs"
        @click="showModal"
        :disabled="isLoading"
        :variant="isLoading ? 'primary' : 'info'"
    >
      <template v-if="isLoading">
        Идет актуализация...
      </template>
      <template v-else>
        <slot>Актуализировать</slot>
      </template>
    </b-btn>
    <ModalWrapper
        title="Вы уверены, что хотите актуализировать данные?"
        name="ConfirmActualization"
        @submit="confirmAndSubmit"
        @close-modal="closeModal"
        v-model="showActualizeModal"
    />
    <div style="color: #666666" class="mt-2">
      Дата актуализации:
    </div>
  </div>
</template>

<script>
import ModalWrapper from "@/components/modals/index.vue";

export default {
  inheritAttrs: false,
  components: {
    ModalWrapper,
  },
  props: {
    actualizeFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showActualizeModal: false, // Контроль состояния модального окна
    };
  },
  methods: {
    // Открытие модального окна
    showModal() {
      this.showActualizeModal = true;
    },
    // Закрытие модального окна
    closeModal() {
      this.showActualizeModal = false;
    },
    // Подтверждение и выполнение функции
    async confirmAndSubmit() {
      try {
        this.isLoading = true;
        this.closeModal(); // Закрываем модальное окно
        await this.actualizeFunction(); // Выполняем функцию из пропса
      } catch (error) {
        console.error("Ошибка при выполнении функции актуализации:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
