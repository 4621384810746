import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/v1/client/auth/login/via-email';

export default {
    /**
     * POST: login
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    login: (params) => adminHttp.post(END_POINT,params),
}