<template>
  <div>
    <Breadcrumbs main="Голосование" title="Создание голосования"></Breadcrumbs>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Создание голосования</h5>
            </div>
            <div class="card-body">
              <b-card>
                <ValidationObserver ref="form">
                  <b-form @submit.prevent="submitVote">
                    <b-tabs class="mt-3 d-flex justify-content-md-start justify-content-center flex-wrap">
                      <b-tab v-for="lang in languages"
                             :title="lang.language"
                             :key="'lang-' + lang.id"
                             @click="getLanguageFromBtn(lang.language.toLowerCase())"
                             nav-class="mr-2 mb-2">
                        <template #title>
                          <div class="d-flex justify-content-center">
                            <img :src="lang.img" width="20" height="20" class="mr-2">
                            <span>{{ lang.language }}</span>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>

                    <div class="mt-3">
                      <label class="font-weight-bold star">Название опроса {{ language.toUpperCase() }}</label>
                      <ValidationProvider name="Название опроса" rules="required|min:2" v-slot="{ errors }">
                        <b-form-input
                            v-model="voteData.title[language]"
                            :placeholder="'Введите название на ' + language.toUpperCase()"
                            :state="!errors.length ? null : false"
                        ></b-form-input>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-3">
                      <label class="font-weight-bold star">Описание {{ language.toUpperCase() }}</label>
                      <ValidationProvider name="Описание" rules="required|min:2" v-slot="{ errors }">
                        <b-form-textarea
                            v-model="voteData.description[language]"
                            :placeholder="'Введите описание на ' + language.toUpperCase()"
                            :state="!errors.length ? null : false"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold">Варианты ответа</label>
                      <div v-for="(answer, index) in voteData.answers" :key="'answer-variant-' + index"
                           class="d-flex mb-2">
                        <b-form-input
                            :value="answer"
                            readonly
                        ></b-form-input>
                        <b-button variant="danger" @click="removeAnswer(index)" class="ml-2">X</b-button>
                      </div>
                      <div class="mt-2">
                        <div class="d-flex justify-content-between">
                          <b-form-input
                              v-model="answerName"
                              placeholder="Название варианта ответа"
                              class="mr-1"
                          ></b-form-input>
                          <b-btn
                            @click="addAnswer"
                          >Добавить</b-btn>
                        </div>

                      </div>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Часовой пояс</label>
                      <ValidationProvider name="Часовой пояс" rules="required" v-slot="{ errors }">
                        <b-form-select
                            v-model="voteData.timezone"
                            :options="timezoneOptions"
                            :state="!errors.length ? null : false"
                        ></b-form-select>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Дата начала голосования</label>
                      <ValidationProvider name="Дата начала" rules="required" v-slot="{ errors }">
                        <DatePicker
                            v-model="voteData.start_at"
                            mode="dateTime"
                            :model-config="modelConfig"
                            :min-date="new Date()"
                            is24hr
                            :popover="{ visibility: 'click' }"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                                :value="inputValue"
                                v-on="inputEvents"
                                :state="!errors.length ? null : false"
                            />
                          </template>
                        </DatePicker>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <label class="font-weight-bold star">Дата окончания голосования</label>
                      <ValidationProvider name="Дата окончания" rules="required" v-slot="{ errors }">
                        <DatePicker
                            v-model="voteData.end_at"
                            mode="dateTime"
                            :model-config="modelConfig"
                            :min-date="voteData.start_at"
                            is24hr
                            :popover="{ visibility: 'click' }"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                                :value="inputValue"
                                v-on="inputEvents"
                                :state="!errors.length ? null : false"
                            />
                          </template>
                        </DatePicker>
                        <div class="text-danger">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <b-button type="submit" variant="success" class="mt-4">Создать голосование</b-button>
                  </b-form>
                </ValidationObserver>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {LanguageAdapterMixin} from "@/mixins/Language/LanguageAdapterMixin";
import {ValidationObserver, ValidationProvider} from 'vee-validate';

export default {
  name: 'CreateVote',
  mixins: [LanguageAdapterMixin],
  components: {
    DatePicker,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      voteData: {
        title: {
          ru: '',
          en: '',
          kz: '',
          uz: '',
        },
        description: {
          ru: '',
          en: '',
          kz: '',
          uz: '',
        },
        answers: [],
        start_at: '',
        end_at: '',
        timezone: 'Europe/Moscow',
      },
      answerName: '',
      searchResults: [],
      answerOptions: [],
      timezoneOptions: [
        {value: 'Europe/Moscow', text: 'Moscow (GMT+3)'},
        {value: 'Europe/London', text: 'London (GMT+1)'},
        {value: 'America/New_York', text: 'New York (GMT-4)'},
        {value: 'Asia/Tokyo', text: 'Tokyo (GMT+9)'},
        {value: 'Asia/Dubai', text: 'Dubai (GMT+4)'},
        {value: 'Asia/Almaty', text: 'Almaty (GMT+6)'},
        {value: 'Asia/Tashkent', text: 'Tashkent (GMT+5)'},
        // Add more timezone options as needed
      ],
    };
  },
  computed: {
    ...mapGetters({
      teams: 'getTeams',
    }),
  },
  methods: {
    ...mapActions('moduleVoting', ['createVote']),
    ...mapActions(['indexTeams']),
    formatDate(dateString) {
      return dateString ? dateString + ':00' : '';
    },
    addAnswer() {
      if (this.answerName.length < 1) {
        return
      }

      this.voteData.answers.push(this.answerName);
      this.answerName = '';
    },
    removeAnswer(index) {
      this.answerOptions.splice(index, 1);
      this.voteData.answers.splice(index, 1);
    },
    async submitVote() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      try {
        const formattedVoteData = {
          ...this.voteData,
          start_at: this.formatDate(this.voteData.start_at),
          end_at: this.formatDate(this.voteData.end_at),
        };

        console.log('Submitting vote data:', formattedVoteData);
        const response = await this.createVote(formattedVoteData);
        console.log('Vote created:', response);
        this.$toast.success('Голосование успешно создано', {position: 'top-right', timeout: 2000});
        this.$router.push({name: 'list_votes'});
      } catch (error) {
        console.error('Error creating vote:', error);
        this.$toast.error('Ошибка при создании голосования', {position: 'top-right', timeout: 2000});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/star.scss";
</style>