<template>
  <b-form-input
      v-model="text"
      placeholder="Поиск команды"
      @input="onInput"
  ></b-form-input>
</template>

<script>
export default {
  data() {
    return {
      text: '', // Текст в поле ввода
      debounceTimeout: null, // Таймер для debounce
    };
  },
  watch: {
    // Инициализируем значение из query при загрузке компонента
    $route: {
      immediate: true,
      handler(newRoute) {
        this.text = newRoute.query.search || ''; // Устанавливаем значение из query
      },
    },
  },
  methods: {
    onInput() {
      // Очищаем предыдущий таймер
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      // Устанавливаем новый таймер для обновления query
      this.debounceTimeout = setTimeout(() => {
        this.updateQuery();
      }, 500); // Задержка 0,5 секунды
    },
    updateQuery() {
      if (this.text.trim() === '') {
        // Если текст пустой, удаляем параметр search
        const {search, ...queryWithoutSearch} = this.$route.query;
        if ('search' in this.$route.query) {
          // Удаляем search только если он есть
          this.$router.push({
            path: this.$route.path,
            query: queryWithoutSearch,
          });
        }
      } else {
        // Если есть текст, добавляем/обновляем search
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search: this.text.trim(),
          },
        });
      }
    },
  },
};
</script>
