import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/vote';

export default {
    /**
     * POST: Create a new vote
     * @param {Object} voteData - The data for the new vote
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (voteData) => adminHttp.post(END_POINT, { vote: voteData }),

    /**
     * GET: Fetch votes with filters
     * @param {Object} params - Query parameters for filtering
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params = {}) => adminHttp.get(END_POINT, { params }),

    /**
     * PUT: Update an existing vote
     * @param {number|string} id - The ID of the vote to update
     * @param {Object} voteData - The updated vote data
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (id, voteData) => adminHttp.put(`${END_POINT}/${id}`, { vote: voteData }),

    /**
     * DELETE: Delete a vote
     * @param {number|string} id - The ID of the vote to delete
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => adminHttp.delete(`${END_POINT}/${id}`),

    /**
     * GET: Fetch a specific vote
     * @param {number|string} id - The ID of the vote to fetch
     * @returns {Promise<AxiosResponse<any>>}
     */
    show: (id) => adminHttp.get(`${END_POINT}/${id}`),

    /**
     * POST: Finish a vote
     * @param {number|string} id - The ID of the vote to finish
     * @returns {Promise<AxiosResponse<any>>}
     */
    finish: (id) => adminHttp.post(`${END_POINT}/finish/${id}`)
};