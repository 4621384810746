<template>
  <div class="group-card-wrapper"
       :id="`group-item-workspace-${ group.id }`"
       @mouseover="hoverOnGroup(group)">
    <header>
      <h5>
        {{group.name}}
        <div v-if="getIconByStatusGroup(group?.status_code?.code)" class="status-icon">
          <img :src="getIconByStatusGroup(group?.status_code?.code).black" alt="">
        </div>
      </h5>
      <div v-if="!isCompletedTournament" class="actions">
        <b-button variant="outline-secondary"
                  v-if="group.group_teams.length"
                  @click="isShowPlayers = !isShowPlayers"
                  class="header-action-2">
          <span class="d-none d-sm-block"> {{ isShowPlayers ? 'Скрыть игроков' : 'Показать игроков' }}</span>
          <span class="d-sm-none d-block"><i class="fa fa-users fa-lg" aria-hidden="true"></i></span>
        </b-button>
        <b-button variant="outline-secondary"
                  @click="editMatchesByGroup(group)"
                  class="header-action-2">
          <span class="d-none d-sm-block">Добавить матч</span>
          <span class="d-sm-none d-block"><i class="fa fa-trophy fa-lg" aria-hidden="true"></i> +</span>
        </b-button>
        <b-button variant="primary"
                  class="header-action"
                  @click="addTeamsByGroup(group)"
        >
          <span class="d-none d-sm-block">Добавить команду</span>
          <span class="d-sm-none d-block"><i class="fa fa-users fa-lg" aria-hidden="true"></i> +</span>
        </b-button>
      </div>
    </header>
    <div class="body">
      <div class="main-actions-teams">
        <div class="action-checkbox">
          <template v-if="!isCompletedTournament">
            <b-form-checkbox size="lg"
                             v-for="groupTeam in group.group_teams"
                             v-model="selectedTeams"
                             :value="{
                               ...groupTeam.team,
                               stage_team_id: groupTeam.stage_team_id
                             }"
                             :key="`${ groupTeam?.id }-${ groupTeam?.team?.name }-checkbox`" />
          </template>
        </div>
        <draggable tag="div"
                   class="team-list"
                   group="groupsTeams"
                   :groupId="group.id"
                   @end="addTeamForTransfer"
                   :disabled="hasWinnerTeamInGroup() || isLoadingSaveTransferredTeams"
                   v-model="group.group_teams">
          <div class="team"
               :style="groupTeam?.group_result?.result_fields?.is_winner === 1 ? {'background': '#7366ff', 'color': 'white'} : {'background': '#f5f7fa'}"
               :class="[
                   teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id)?.error && 'error-team',
                   teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id) && !teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id).error && 'transferred',
               ]"
               v-for="groupTeam in group.group_teams"
               :key="groupTeam.id"
          >
            <template v-if="teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id)?.error">
              {{ groupTeam?.team?.name }}: Не перенесено!
            </template>
            <template v-else-if="teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id) && isLoadingSaveTransferredTeams">
              Сохраняем перенос...
            </template>
            <template v-else>
              {{ groupTeam?.team?.name }}
            </template>
          </div>
        </draggable>
<!--        <div class="team-list d-sm-none d-block">-->
<!--          <div class="team"-->
<!--               :style="groupTeam?.group_result?.result_fields?.is_winner === 1 ? {'background': '#7366ff', 'color': 'white'} : {'background': '#f5f7fa'}"-->
<!--               :class="[-->
<!--                   teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id)?.error && 'error-team',-->
<!--                   teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id) && !teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id).error && 'transferred',-->
<!--               ]"-->
<!--               v-for="groupTeam in group.group_teams"-->
<!--               :key="groupTeam.id"-->
<!--          >-->
<!--            <template v-if="teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id)?.error">-->
<!--              {{ groupTeam.team.name }}: Не перенесено!-->
<!--            </template>-->
<!--            <template-->
<!--                v-else-if="teamsForTransfer.find((team) => team.groupResultTeamId === groupTeam.id) && isLoadingSaveTransferredTeams">-->
<!--              Сохраняем перенос...-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              {{ groupTeam.team.name }}-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
        <div class="wins-counters">
          <WinsCounter v-for="groupTeam in group.group_teams"
                       :teamResult="groupTeam"
                       :key="`${groupTeam.id}-${groupTeam?.team?.id}-winCount`"/>
        </div>
        <div class="wins-team">
          <MakeWinnerTeam v-for="groupTeam in group.group_teams"
                       v-if="!hasWinnerTeamInGroup()"
                       :teamResult="groupTeam"
                       :key="`${groupTeam.id}-${groupTeam?.team?.id}-winsTeam`" />
        </div>
        <div class="trash-actions">
          <template v-if="!isCompletedTournament">
            <div class="trash-action" v-for="groupTeam in group.group_teams" :key="`${ groupTeam?.id }-${ groupTeam?.team?.name }-trash`">
              <DeleteTeam :groupTeam="groupTeam"/>
            </div>
          </template>
        </div>
      </div>
      <div v-if="isShowPlayers" class="team-list-data">
        <div v-for="({ team, players }) in teamsPlayers" class="team">
          <h4>{{ team.name }}</h4>
          <table>
            <tr>
              <td>Username</td>
              <td>Nick</td>
              <td>MLBB ID</td>
            </tr>
            <tr v-for="player in players" :key="'player-' + player.id">
              <td>{{ player.username }}</td>
              <td>{{ getGameProfile(player)?.fields.nick }}</td>
              <td>{{ getGameProfile(player)?.fields.mlbb_id }}</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import StopBlackIcon from '@/assets/icons/stop-black.svg'
import RotateBlackIcon from '@/assets/icons/rotate-black.svg'
import FinishBlackIcon from '@/assets/icons/finish-black.svg'

import StopWhiteIcon from '@/assets/icons/stop-white.svg'
import RotateWhiteIcon from '@/assets/icons/rotate-white.svg'
import FinishWhiteIcon from '@/assets/icons/finish-white.svg'

import MakeWinnerTeam from "@/pages/Tournament/StandingsTournament/Games/MakeWinnerTeam.vue";
import WinsCounter from "@/pages/Tournament/StandingsTournament/Games/WinsCounter.vue";
import DeleteTeam from "@/components/Tournament/components/DeleteTeam.vue";
import draggable from 'vuedraggable';

export default {
  name: "GroupCard",
  components: {WinsCounter, draggable, DeleteTeam, MakeWinnerTeam },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    group: {
      type: Object,
      required: true
    },
    teamsForTransfer: {
      type: Array,
      required: true
    },
    isLoadingSaveTransferredTeams: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowPlayers: false,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament']),
    ...mapGetters('moduleNewTournament', ['selectedStage', 'isCompletedTournament']),
    selectedGroupId: {
      get() { return this.$store.state.moduleNewTournament.selectedGroupId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedGroupId', value: newValue }) }
    },
    selectedTeams: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    },
    teamsPlayers() {
      return this.group.group_teams.map(({ team, group_users }) => ({
        team,
        players: group_users
      }))
    }
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    addTeamsByGroup(group) {
      this.selectedGroupId = group.id
      this.$emit('changeWorkspace', 'AddTeam')
    },
    editMatchesByGroup(group) {
      this.selectedGroupId = group.id
      this.$emit('changeWorkspace', 'EditorMatches')
    },
    getIconByStatusGroup(statusCode) {
      if (statusCode === 'not_started_status') return { black: StopBlackIcon, white: StopWhiteIcon }
      if (statusCode === 'game_process_status') return { black: RotateBlackIcon, white: RotateWhiteIcon }
      if (statusCode === 'ended_status') return { black: FinishBlackIcon, white: FinishWhiteIcon }
      return false
    },
    hoverOnGroup(group) {
      const listGroups = document.getElementById('list-groups-sidebar')
      const groupItem = document.getElementById(`group-item-sidebar-${ group.id }`)
      // this.selectGroup(group)
      this.selectedGroupId = group.id

      const parentRect = listGroups.getBoundingClientRect()
      const childRect = groupItem.getBoundingClientRect()

      const isSee = (
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.bottom &&
          childRect.left >= parentRect.left &&
          childRect.right <= parentRect.right
      )

      if(!isSee) {
        listGroups.scrollTo({
          top: groupItem.offsetTop - 200,
          behavior: 'smooth'
        })
      }
    },
    addTeamForTransfer($event) {
      console.log('$event ', $event)

      const transferTeam = {
        oldGroupId: $event.from.__vue__.$attrs.groupId,
        newGroupId: $event.to.__vue__.$attrs.groupId,
        groupResultTeamId: $event.item._underlying_vm_.id,
        teamId: $event.item._underlying_vm_.team.id,
        stageTeamId: $event.item._underlying_vm_.stage_team_id
      }

      this.$emit('transferredTeam', transferTeam)
    },
    getGameProfile(player) {
      return player.game_profiles.find(({ game_id }) => game_id === this.tournament.game_id)
    },
    hasWinnerTeamInGroup() {
     return this.group.group_teams.find((winnerTeam) => winnerTeam.group_result?.result_fields?.is_winner === 1)
    }
  }
}
</script>

<style scoped lang="scss">
.group-card-wrapper {
  background: white;
  padding: 20px;
  border-radius: 10px;
  transition: .2s;

  &.active {
    background: #7366ffbf;
  }

  h5 {
    margin: 0;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .status-icon {
      margin-left: 10px;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;

    .actions {
      .header-action {
        border-radius: 15px;
        font-size: 11px;
        padding: 5px 18px;

        @media screen and (max-width: 800px) {
          padding: 10px 15px;
        }
      }
      .header-action-2 {
        border-radius: 15px;
        font-size: 11px;
        padding: 5px 18px;
        color: #6c757d;
        border-color: #6c757d;
        margin-right: 10px;

        @media screen and (max-width: 800px) {
          padding: 10px 15px;
        }

        &:hover, &:active, &:focus {
          color: #fff !important;
          background-color: #6c757d !important;
          border-color: #6c757d !important;
        }
      }
    }
  }

  .body {
    .main-actions-teams {
      display: grid;
      grid-template-columns: 42px 200px 150px 25px auto;
      gap: 10px;

      @media screen and (max-width: 575px) {
        grid-template-columns: 20px 120px 40px auto auto;
      }

      & > * {
        align-self: center;
      }

      & > *:first-child {
        justify-self: center;
        width: 100%;
      }

      .action-checkbox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 575px) {
          padding: 15px 18px;
        }

        & > * {
          height: 42px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;

          &::v-deep {
            label::before,
            label::after {
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .wins-counters {
        padding: 2px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 575px) {
          width: min-content;
          justify-content: space-around;
        }
      }
      .wins-team {
        padding: 2px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
      }

      .trash-actions {
        padding: 2px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
      }

      .team-list {
        & > *:not(:last-child) {
          border-bottom: 1px solid gray;
        }

        & > *:last-child {
          //border-radius: 0 0 10px 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        & > *:first-child {
          //border-radius: 10px 10px 0 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .team {
          padding: 10px 18px;
          //background: #8080801f;
          //background: #f5f7fa80;
          //background: #f5f7fa;
          border-color: #dae0ff;
          cursor: move;
          transition: .2s;

          @media screen and (max-width: 575px) {
            background: none;
            padding: 25px 18px;
          }

          &.transferred {
            animation: activeTeam 2s infinite;
            background: #f5f7fa;

            @keyframes activeTeam {
              0% {
                background: #f5f7fa;
              }
              20% {
                background: #dad6ff;
              }
              90% {
                background: #f5f7fa;
              }
            }
          }

          &.error-team {
            background: #ff00008f;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
    .team-list-data {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      @media screen and (max-width: 560px) {
        display: block;
        overflow-x: scroll;
      }

      h4 {
        font-size: 17px;
        text-align: center;
      }

      table {
        width: 100%;

        tr {
          transition: .2s;
        }

        td {
          padding: 10px;
        }

        & > tr:not(:last-child) {
          border-bottom: 1px solid #00000026;
        }

        & > tr:not(:first-child) {
          &:hover {
            background: #00000026;
          }
        }

        & > tr > td:not(:last-child) {
          border-right: 1px solid #00000026;
        }

        & > tr > td:not(:first-child) {
          text-align: center;
        }
      }

      .team {
        margin-top: 10px;
      }
    }
  }
}
</style>