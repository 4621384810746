<template>
  <div>
    <Breadcrumbs main="Голосование" title="Результаты голосования" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Результаты голосования</h5>
            </div>
            <div class="card-body">
              <b-card v-if="isLoading">
                <b-spinner label="Loading..."></b-spinner>
              </b-card>
              <b-card v-else-if="currentVote">
                <h3>{{ getCurrentVoteTitle }}</h3>
                <p>{{ getCurrentVoteDescription }}</p>
                <p><strong>Начало:</strong> {{ formatDate(currentVote.start_at) }}</p>
                <p><strong>Окончание:</strong> {{ formatDate(currentVote.end_at) }}</p>
                <p><strong>Временная зона:</strong> {{ currentVote.timezone }}</p>
                <p><strong>Всего голосов:</strong> {{ currentVote.all_votes_count }}</p>

                <h4 class="mt-4">Результаты:</h4>
                <!-- <b-table striped hover :items="getTeamResults" :fields="fields">
                  <template #cell(percentage)="data">
                    {{ calculatePercentage(data.item.vote_data.team_votes_count) }}%
                  </template>
                  <template #cell(progress)="data">
                    <b-progress :value="calculatePercentage(data.item.vote_data.team_votes_count)" :max="100" show-progress animated></b-progress>
                  </template>
                </b-table> -->
                <b-table striped hover :items="getTeamResults" :fields="fields">
                  <template #cell(name)="data">
                    <router-link :to="{ name: 'teams_info', params: { id: data.item.id } }">
                      {{ data.item.name }}
                    </router-link>
                  </template>
                  <template #cell(percentage)="data">
                    {{ calculatePercentage(data.item.votes_count) }}%
                  </template>
                  <template #cell(progress)="data">
                    <b-progress :value="calculatePercentage(data.item.vote_data.team_votes_count)" :max="100" show-progress animated></b-progress>
                  </template>
                </b-table>

                <b-button variant="primary" @click="goBack" class="mt-3">Назад к списку голосований</b-button>
              </b-card>
              <b-card v-else>
                <p>Голосование не найдено.</p>
                <b-button variant="primary" @click="goBack">Назад к списку голосований</b-button>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VoteResults',
  data() {
    return {
      isLoading: true,
      fields: [
        { key: 'value.text', label: 'Название' },
        { key: 'votes_count', label: 'Количество голосов' },
        { key: 'percentage', label: 'Процент' },
      ]
    };
  },
  computed: {
    ...mapGetters('moduleVoting', ['getCurrentVote']),
    currentVote() {
      return this.getCurrentVote;
    },
    getCurrentVoteTitle() {
      const title = this.currentVote?.title;
      return title?.ru || title?.en || title?.kz || title?.uz || 'Название отсутствует';
    },
    getCurrentVoteDescription() {
      const description = this.currentVote?.description;
      return description?.ru || description?.en || description?.kz || description?.uz || 'Описание отсутствует';
    },
    getTeamResults() {
      return this.currentVote?.vote_answers || [];
    }
  },
  mounted() {
    this.loadVoteResults();
  },
  methods: {
    ...mapActions('moduleVoting', ['fetchVote']),
    async loadVoteResults() {
      this.isLoading = true;
      try {
        await this.fetchVote(this.$route.params.id);
      } catch (error) {
        console.error('Error loading vote results:', error);
        this.$toast.error('Ошибка при загрузке результатов голосования', { position: 'top-right', timeout: 2000 });
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    calculatePercentage(votes) {
      if (!this.currentVote || this.currentVote.all_votes_count === 0) return 0;
      return ((votes / this.currentVote.all_votes_count) * 100).toFixed(2);
    },
    goBack() {
      this.$router.push({ name: 'list_votes' });
    },
  }
};
</script>

<style scoped>
.card-body {
  padding: 1.25rem;
}
</style>