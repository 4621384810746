<template>
  <div>
    <Breadcrumbs main="Рассылка" title="Телеграм рассылки"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-2">
                <SearchInput v-model="searchForMailingsModel" @search="getFilteredMailings()"
                             :placeholder-name="'Поиск рассылок'"/>
              </div>
              <div class="notification-for-search mb-4">Поиск по заголовку рассылки</div>
              <b-button v-b-modal.modal-xl variant="primary" @click="$router.push({name: 'newsletter_create'})"
                        class="mb-4">
                Создать телеграм рассылку
              </b-button>
              <div>
                <div v-if="isLoaded" class="loading-wrapper">
                  <b-spinner class="spinner"></b-spinner>
                </div>
                <b-table
                    v-else
                    responsive
                    ref="table"
                    show-empty
                    stacked="md"
                    :striped="striped"
                    :bordered="bordered"
                    :items="telegramMailingList"
                    :fields="fields"
                    :current-page=0
                    :per-page=10
                    class="mb-5"
                >
                  <template v-slot:cell(title)="{ item }">
                    {{ item.title }}
                  </template>
                  <template v-slot:cell(text)="{ item }">
                    {{ item.text }}
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    {{ item.status_name }}
                  </template>
                  <template v-slot:cell(image)="{ item }">
                    <div class="d-flex justify-content-center">

                      <img :src="item.image?.original_url" class="" width="50" height="50">
                    </div>
                  </template>
                  <template #cell(action)="{item}">
                    <b-btn
                        @click="openModal(item)"
                        type="button" class="btn mr-1 p-1 btn-primary">
                      run
                      <i class="fa fa-play" aria-hidden="true"></i>
                    </b-btn>

                    <b-btn
                        @click="showLogs(item.id)"
                        type="button" class="btn mr-1 p-1 btn-primary">
                      <i class="fa fa-address-card" aria-hidden="true"></i>
                    </b-btn>

                  </template>

                </b-table>
              </div>
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="telegramMailingCurrentPage"
                    :total-rows="telegramMailingTotalPagination"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
    <Modals v-model="showMailingModal"
            name="RunMailing"
            title="Вы действительно хотите запустить рассылку"
            @submit="()=>{}"
            :params="{ mailing: selectedMailing }"/>
  </div>
</template>

<script>
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {MailingAdapterMixin} from "@/mixins/Mailing/MailingAdapterMixin";
import 'emoji-picker-element';
import insertTextAtCursor from 'insert-text-at-cursor';
import SearchInput from "@/components/SearchInput.vue";
import api from "@/api/api";
import router from "@/router";
import Modals from "@/components/modals/index.vue";

export default {
  name: "TelegramNewsletters",
  computed: {
    api() {
      return api
    }
  },
  components: {Modals, SearchInput},
  mixins: [PaginationHelperMixin, MailingAdapterMixin],
  mounted() {
    try {
      (async () => {
        this.isLoaded = true
        this.recordPageToStorage(this.telegramMailingCurrentPage, this.pageKey)
        await this.grabTelegramMailingList({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        })
        document.addEventListener('emoji-click', e => {
          insertTextAtCursor(document.getElementById('textarea'), e.detail.unicode)
        })
      })()
    } finally {
      this.isLoaded = false
    }
  },
  data() {
    return {
      searchForMailingsModel: '',
      pageKey: 'pageNumberTelegramNewsletters',
      fields: [
        {key: 'id', label: 'id', sortable: true,},
        {key: 'title', label: 'Заголовок', sortable: true,},
        {key: 'text', label: 'Описание', sortable: true,},
        {key: 'image', label: 'Изображение', sortable: false,},
        {key: 'status', label: 'Статус', sortable: false,},
        {key: 'action', label: 'Действия', sortable: false,},
      ],
      striped: true,
      bordered: true,
      showMailingModal: null,
      selectedMailing: {},
    };
  },
  methods: {
    router() {
      return router
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabTelegramMailingList({
        pageNumber: pageNumber,
        ...(this.searchForMailingsModel && {search: this.searchForMailingsModel}),
      })
    },
    showLogs(id) {
      this.$router.push({
        name: 'telegram_newsletter_log',
        params: {
          id: id
        }
      })
    },

    openModal(item) {
      this.selectedMailing = item;
      this.showMailingModal = true;
    },

    getFilteredMailings() {
      this.grabTelegramMailingList({search: this.searchForMailingsModel})
    },
  },

};
</script>

<style scoped>

b-textarea {
  display: block;
}
</style>