<template>
  <div>
    <Modal 
      name="DeleteVoteModal" 
      title="Удаление голосования" 
      v-model="isShowDeleteVoteModal"
      :params="{ id: selectedVoteId }"
      @voteDeleted="handleVoteDeleted"
    />
    <div class="container-fluid">
      <Breadcrumbs main="Голосование" title="Список голосований"/>
      <b-card>
        <div class="d-flex">
          <SearchInput 
            v-model="searchForVotesModel" 
            @search="getFilteredVotes()" 
            :placeholder-name="'Поиск голосований'"
          />
        </div>
        <div class="notification-for-search mb-4">Поиск по названию голосования</div>

        <!-- Responsive layout with dropdown for status options -->
        <div class="row mb-3 align-items-center">
          <div class="col-12 col-md-9">
            <b-dropdown id="dropdown-1" variant="info" :text="selectedStatusText" class="mb-2">
              <b-dropdown-item
                v-for="option in statusOptions"
                :key="option.value"
                @click="setStatusFilter(option)"
              >
                {{ option.text }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-12 col-md-3 text-md-right mt-3 mt-md-0">
            <b-button 
              variant="primary" 
              class="w-100 w-md-auto ml-md-auto"
              :to="{ name: 'create_vote' }"
            >
              Создать голосование
            </b-button>
          </div>
        </div>

        <div v-if="isLoading" class="loading-wrapper">
          <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
          v-else
          responsive
          show-empty
          stacked="md"
          :striped="striped"
          :bordered="bordered"
          :items="votes"
          :fields="fields"
          :current-page="0"
          :per-page="10"
          class="mb-5 table-no-scroll"
          empty-text="Нет доступных голосований"
        >
          <template #cell(title)="data">
            {{ data.item.title.ru || data.item.title.en || data.item.title.kz || data.item.title.uz }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="getStatusVariant(data.item)">
              {{ getStatusText(data.item) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" :to="{ name: 'edit_vote', params: { id: data.item.id } }" title="Edit">
                <i class="fa fa-edit"></i>
              </b-btn>
              <b-btn variant="info" class="mr-1 p-1" :to="{ name: 'vote_results', params: { id: data.item.id } }" title="View Results">
                <i class="fa fa-eye"></i>
              </b-btn>
              <b-btn variant="danger" class="p-1" @click="deleteVote(data.item.id)" title="Delete">
                <i class="fa fa-trash"></i>
              </b-btn>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
            @change="handlePageChange"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SearchInput from "@/components/SearchInput.vue";
import Modal from '@/components/modals/index.vue'

export default {
  name: 'ListVotes',
  components: { SearchInput, Modal },
  data() {
    return {
      searchForVotesModel: '',
      currentStatus: null,
      selectedStatusText: 'Фильтр по статусу', // Initial text
      statusOptions: [
        { text: 'Все', value: null, variant: 'secondary' },
        { text: 'Активные', value: 'active', variant: 'success' },
        { text: 'Завершенные', value: 'finished', variant: 'info' },
        { text: 'Предстоящие', value: 'upcoming', variant: 'warning' }
      ],
      fields: [
        { key: 'id', label: '№', sortable: true },
        { key: 'title', label: 'Название', sortable: true },
        { key: 'start_at', label: 'Дата начала', sortable: true },
        { key: 'end_at', label: 'Дата окончания', sortable: true },
        { key: 'status', label: 'Статус', sortable: true },
        { key: 'actions', label: 'Действия', sortable: false }
      ],
      striped: true,
      bordered: true,
      isLoading: false,
      currentPage: 1,
      isShowDeleteVoteModal: false,
      selectedVoteId: null,
    };
  },
  computed: {
    ...mapGetters('moduleVoting', ['getVotes', 'getVotesPaginationTotal']),
    votes() {
      return this.getVotes;
    },
    totalRows() {
      return this.getVotesPaginationTotal;
    }
  },
  mounted() {
    this.loadVotes();
  },
  methods: {
    ...mapActions('moduleVoting', ['fetchVotes', 'deleteVote']),
    async loadVotes() {
      this.isLoading = true;
      try {
        // Construct the query parameters as separate parameters
        const params = {
          page: this.currentPage,
        };

        if (this.searchForVotesModel.trim()) {
          params.search = this.searchForVotesModel.trim();
        }

        if (this.currentStatus) {
          params[`filter[only_${this.currentStatus}]`] = true;
        }

        // Send the request with properly structured parameters
        await this.fetchVotes(params);
      } catch (error) {
        console.error('Error loading votes:', error);
        this.$bvToast.toast('Ошибка при загрузке голосований', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      } finally {
        this.isLoading = false;
      }
    },
    getFilteredVotes() {
      this.currentPage = 1;
      this.loadVotes();
    },
    handlePageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.loadVotes();
    },
    setStatusFilter(option) {
      // Update current status and dropdown label
      this.currentStatus = option.value;
      this.selectedStatusText = option.text;
      this.getFilteredVotes();
    },
    getStatusVariant(status) {
      switch (status) {
        case 'active': return 'success';
        case 'upcoming': return 'warning';
        case 'finished': return 'info';
        default: return 'secondary';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'active': return 'Активное';
        case 'upcoming': return 'Предстоящее';
        case 'finished': return 'Завершенное';
        default: return 'Неизвестно';
      }
    },
    deleteVote(id) {
      this.selectedVoteId = id;
      this.isShowDeleteVoteModal = true;
    },
    handleVoteDeleted() {
      this.loadVotes();
      this.isShowDeleteVoteModal = false;
      this.selectedVoteId = null;
    },
    getStatusVariant(vote) {
      const now = new Date();
      const startDate = new Date(vote.start_at);
      const endDate = new Date(vote.end_at);

      if (now < startDate) {
        return 'warning'; // Предстоящее
      } else if (now >= startDate && now <= endDate) {
        return 'success'; // Активное
      } else {
        return 'info'; // Завершенное
      }
    },

    getStatusText(vote) {
      const now = new Date();
      const startDate = new Date(vote.start_at);
      const endDate = new Date(vote.end_at);

      if (now < startDate) {
        return 'Предстоящее';
      } else if (now >= startDate && now <= endDate) {
        return 'Активное';
      } else {
        return 'Завершенное';
      }
    },
  }
};
</script>

<style scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.notification-for-search {
  font-size: 0.9em;
  color: #6c757d;
}
.table-no-scroll {
  overflow-x: hidden !important;
}
</style>