<template>
  <div class="overflow-auto" v-if="computedRows > 0"> <!-- Показываем только если есть строки -->
    <b-pagination
        v-model="currentPage"
        :total-rows="computedRows"
        :per-page="perPage"
        first-number
        @input="updateQuery"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true, // Требуем передать общее количество элементов
    },
    queryName: {
      type: String,
      default: 'page',
    },
  },
  data() {
    return {
      perPage: 1, // Количество элементов на страницу
      currentPage: 1, // Текущая страница
    };
  },
  computed: {
    // Общее количество строк для пагинации
    computedRows() {
      return this.total || 0; // Обрабатываем случай, когда total может быть 0
    },
  },
  watch: {
    total(newValue, oldValue) {
      // Если total меняется с 0 на новое значение, проверяем текущую страницу
      if (oldValue === 0 && newValue > 0) {
        const initialPage = parseInt(this.$route.query[this.queryName]) || 1;
        this.currentPage = initialPage; // Синхронизируем текущую страницу только после получения total
      }
    },
  },
  methods: {
    // Обновление query параметра
    updateQuery() {
      const currentQueryPage = parseInt(this.$route.query[this.queryName]) || 1;

      // Проверяем, совпадает ли текущий query с целевым
      if (currentQueryPage === this.currentPage) return;

      // Обновляем query только если он отличается
      this.$router.push({
        query: {
          ...this.$route.query,
          [this.queryName]: this.currentPage,
        },
      });
    },
  },
  mounted() {
    // Инициализация текущей страницы из query при загрузке
    if (this.computedRows > 0) {
      const initialPage = parseInt(this.$route.query[this.queryName]) || 1;
      this.currentPage = initialPage;
    }
  },
};
</script>
