import { mapActions, mapGetters, mapMutations } from "vuex";
import * as mutypes from "/src/store/mutation.types";
import Vue from "vue";
import api from "@/api/api";

export const TournamentAdapterMixin = {
  //===============
  // data start
  //===============
  data() {
    return {
      newTournamentName: "",
      newStartReg: "",
      newEndReg: "",
      newStartTournament: "",
      newRules_ru: "",
      newRules_en: "",
      newRules_uz: "",
      newRules_kz: "",
      newRules: {
        ru: "",
        en: "",
        uz: "",
        kz: "",
      },
      newDescription: {
        ru: "",
        en: "",
        uz: "",
        kz: "",
      },
      newSchedule: {
        ru: "",
        en: "",
        uz: "",
        kz: "",
      },
      newMetaTitle: {
        ru: "",
        en: "",
        uz: "",
        kz: "",
      },
      newMetaDescription: {
        ru: "",
        en: "",
        uz: "",
        kz: "",
      },
      newPrizeFond: "",
      newSlotCount: "",
      newFormats: null,
      newTimezone: null,
      values: [],
      newStatus: null,
      newStatusOptions: [
        { value: 2, text: "Registration" },
        { value: 3, text: "Game" },
        { value: 1, text: "Registration not started" },
        { value: 4, text: "Ended" },
      ],
      errorMessage: "",
      newCountry: null,
      newImage: null,
      newYoutubeLink: "",
      selectedFeature: [],
      newSlug: null,
      selectedDivision: [],
      selectedTournamentSeries: null,
      places: [],
      ptsFromGroupTeamsList: [],
      isLoading: false,
      selectedGameId: null,
      isLoadingTournaments: false,
      isHashTournament: false,
      searchForTournamentsModel: "",
      newVariation: null,
    };
  },
  //===============
  // data end
  //===============

    //===============
    // computed start
    //===============
    computed: {
        ...mapGetters({
            getGames: 'getTournamentGames',
            getGameId: 'getTournamentGameId',
            getOrganizers: 'getTournamentOrganizers',
            getOrganizerId: 'getTournamentOrganizerId',
            getName: 'getTournamentName',
            getStartTime: 'getTournamentStartTime',
            getEndRegTime: 'getTournamentEndRegTime',
            getStartRegTime: 'getTournamentStartRegTime',
            getRules: 'getTournamentRules',
            getDescription: 'getTournamentDescription',
            getTournaments: 'getTournaments',
            getPaginationTotal: 'getTournamentsPaginationTotal',
            getCurrentPage: 'getTournamentsPaginationCurrentPage',
            getTournament: 'getTournament',
            getPrizeFond: 'getTournamentPrize',
            getFormats: 'getTournamentsFormats',
            getTimezones: 'getTimezones',
            getSlotCount: 'getTournamentSlotCount',
            getTournamentFormats: 'getTournamentFormats',
            getTournamentTimezone: 'getTournamentTimezone',
            getTournamentCurrentFormats: 'getTournamentCurrentFormats',
            getTournamentCurrentStatus: 'getTournamentCurrentStatus',
            getTournamentSchedule: 'getTournamentSchedule',
            getCountries: 'getCountries',
            getTournamentCurrentCountry: 'getTournamentCurrentCountry',
            getTournamentCurrentImage: 'getTournamentCurrentImage',
            getTournamentCurrentFeature: 'getTournamentCurrentFeature',
            getTournamentCurrentYoutubeLink: 'getTournamentCurrentYoutubeLink',
            getTournamentCurrentSlug: 'getTournamentCurrentSlug',
            getTournamentDivisions: 'getTournamentDivisions',
            getTournamentCurrentDivisions: 'getTournamentCurrentDivisions',
            getTournamentCurrentRelatedTournaments: 'getTournamentCurrentRelatedTournaments',
            getTournamentPlaces: 'getTournamentPlaces',
            getTournamentPrizes: 'getTournamentPrizes',
            getTournamentMetaTitle: 'getTournamentMetaTitle',
            getTournamentMetaDescription: 'getTournamentMetaDescription',
            getTournamentVariations: 'getTournamentVariations',
            getWithComments:'getWithComments'
        }),
        tournament: {
            get() {
                return this.getTournament
            }, set(value) {
                this.setTournament(value)
            }
        },
        games: {
            get() {
                return this.getGames
            }, set(value) {
                this.setGames(value)
            }
        },
        gameId: {
            get() {
                return this.getGameId
            }, set(value) {
                this.setGameId(value)
            }
        },
        organizers: {
            get() {
                return this.getOrganizers
            }, set(value) {
                this.setOrganizers(value)
            }
        },
        organizerId: {
            get() {
                return this.getOrganizerId
            }, set(value) {
                this.setOrganizerId(value)
            }
        },
        endRegTime: {
            get() {
                return this.getEndRegTime
            }, set(value) {
                this.setEndRegTime(value)
            }
        },
        startRegTime: {
            get() {
                return this.getStartRegTime
            }, set(value) {
                this.setRegTime(value)
            }
        },
        name: {
            get() {
                return this.getName
            }, set(value) {
                this.setName(value)
            }
        },
        startTime: {
            get() {
                return this.getStartTime
            }, set(value) {
                this.setStartTime(value)
            }
        },
        rules: {
            get() {
                return this.getRules
            }, set(value) {
                this.setRules(value)
            }
        },
        description: {
            get() {
                return this.getDescription
            }, set(value) {
                this.setDescription(value)
            }
        },
        tournaments: {
            get() {
                return this.getTournaments
            }, set(value) {
                this.setTournaments(value)
            }
        },
        total: {
            get() {
                return this.getPaginationTotal
            }, set(value) {
                this.setPaginationTotal(value)
            }
        },
        currentPage: {
            get() {
                return this.getCurrentPage
            }, set(value) {
                this.setCurrentPage(value)
            }
        },
        prize: {
            get() {
                return this.getPrizeFond
            }, set(value) {
                this.setPrize(value)
            }
        },
        formats: {
            get() {
                return this.getFormats
            }, set(value) {
                this.setFormats(value)
            }
        },
        timezones: {
            get() {
                return this.getTimezones
            }, set(value) {
                this.setTimezones(value)
            }
        },
        slot_count: {
            get() {
                return this.getSlotCount
            }, set(value) {
                this.setSlotCount(value)
            }
        },
        tournament_formats: {
            get() {
                return this.getTournamentFormats
            }, set(value) {
                this.setTournamentFormats(value)
            }
        },
        tournament_timezone: {
            get() {
                return this.getTournamentTimezone
            }, set(value) {
                this.setTournamentTimezone(value)
            }
        },
        currentFormats: {
            get() {
                return this.getTournamentCurrentFormats
            }, set(value) {
                this.setTournamentCurrentFormats(value)
            }
        },
        currentStatus: {
            get() {
                return this.getTournamentCurrentStatus
            }, set(value) {
                this.setTournamentCurrentStatus(value)
            }
        },
        schedule: {
            get() {
                return this.getTournamentSchedule
            }, set(value) {
                this.setTournamentSchedule(value)
            }
        },
        countries: {
            get() {
                return this.getCountries
            }, set(value) {
                this.setCountries(value)
            }
        },
        currentCountry: {
            get() {
                return this.getTournamentCurrentCountry
            }, set(value) {
                this.setTournamentCurrentCountry(value)
            }
        },
        tournamentCurrentImage: {
            get() {
                return this.getTournamentCurrentImage
            }, set(value) {
                this.setTournamentCurrentImage(value)
            }
        },
        currentFeature: {
            get() {
                return this.getTournamentCurrentFeature
            }, set(value) {
                this.setTournamentCurrentFeature(value)
            }
        },
        currentYoutubeLink: {
            get() {
                return this.getTournamentCurrentYoutubeLink
            }, set(value) {
                this.setTournamentCurrentYoutubeLink(value)
            }
        },
        currentSlug: {
            get() {
                return this.getTournamentCurrentSlug
            }, set(value) {
                this.setTournamentCurrentSlug(value)
            }
        },
        divisions: {
            get() {
                return this.getTournamentDivisions
            }, set(value) {
                this.setTournamentDivisions(value)
            }
        },
        currentDivisions: {
            get() {
                return this.getTournamentCurrentDivisions
            }, set(value) {
                this.setTournamentCurrentDivisions(value)
            }
        },
        currentRelatedTournaments: {
            get() {
                return this.getTournamentCurrentRelatedTournaments
            }, set(value) {
                this.setTournamentCurrentRelatedTournaments(value)
            }
        },
        tournamentPlaces: {
            get() {
                return this.getTournamentPlaces
            }, set(value) {
                this.setTournamentPlaces(value)
            }
        },
        tournamentPrizes: {
            get() {
                return this.getTournamentPrizes
            },
            set(value) {
                this.setTournamentPrizes(value)
            }
        },
        metaTitle: {
            get () {
                return this.getTournamentMetaTitle
            },
            set(value) {
                this.setTournamentMetaTitle(value)
            }
        },
        metaDescription: {
            get () {
                return this.getTournamentMetaDescription
            },
            set(value) {
                this.setTournamentMetaDescription(value)
            }
        },
        variations: {
            get() {
                return this.getTournamentVariations
            },
            set(value) {
                this.setTournamentVariations(value)
            }
        },
        withComments: {
            get() {
                return this.getWithComments
            },
            set(value) {
                this.setWithComment(value)
            }
        },
    },
    //===============
    // computed end
    //===============

    //===============
    // methods start
    //===============
    methods: {
        ...mapMutations({
            setTournament: mutypes.SET_TOURNAMENT,
            setGames: mutypes.SET_TOURNAMENT_GAMES,
            setGameId: mutypes.SET_TOURNAMENT_GAME_ID,
            setOrganizers: mutypes.SET_TOURNAMENT_ORGANIZERS,
            setOrganizerId: mutypes.SET_TOURNAMENT_ORGANIZER_ID,
            setEndRegTime: mutypes.SET_TOURNAMENT_END_REG_TIME,
            setRegTime: mutypes.SET_TOURNAMENT_REG_TIME,
            setStartTime: mutypes.SET_TOURNAMENT_START_TIME,
            setName: mutypes.SET_TOURNAMENT_NAME,
            setRules: mutypes.SET_TOURNAMENT_RULES,
            setDescription: mutypes.SET_TOURNAMENT_DESCRIPTION,
            setTournaments: mutypes.SET_TOURNAMENTS,
            setPaginationTotal: mutypes.SET_TOURNAMENTS_PAGINATION_TOTAL,
            setCurrentPage: mutypes.SET_TOURNAMENTS_PAGINATION_CURRENT_PAGE,
            setPrize: mutypes.SET_TOURNAMENT_PRIZE_FOND,
            setFormats: mutypes.SET_TOURNAMENTS_FORMATS,
            setTimezones: mutypes.SET_TIMEZONES,
            setSlotCount: mutypes.SET_TOURNAMENT_SLOT_COUNT,
            setTournamentFormats: mutypes.SET_TOURNAMENT_FORMATS,
            setTournamentTimezone: mutypes.SET_TOURNAMENT_TIMEZONE,
            setTournamentCurrentFormats: mutypes.SET_TOURNAMENT_CURRENT_FORMATS,
            setTournamentCurrentStatus: mutypes.SET_TOURNAMENT_CURRENT_STATUS,
            setTournamentSchedule: mutypes.SET_TOURNAMENT_SCHEDULE,
            setCountries: mutypes.SET_COUNTRIES,
            setTournamentCurrentCountry: mutypes.SET_TOURNAMENT_CURRENT_COUNTRY,
            setTournamentCurrentImage: mutypes.SET_TOURNAMENT_CURRENT_IMAGE,
            setTournamentCurrentFeature: mutypes.SET_TOURNAMENT_CURRENT_FEATURE,
            setTournamentCurrentYoutubeLink: mutypes.SET_TOURNAMENT_CURRENT_YOUTUBE_LINK,
            setTournamentCurrentSlug: mutypes.SET_TOURNAMENT_CURRENT_SLUG,
            setTournamentDivisions: mutypes.SET_TOURNAMENT_DIVISIONS,
            setTournamentCurrentDivisions: mutypes.SET_TOURNAMENT_CURRENT_DIVISIONS,
            setTournamentCurrentRelatedTournaments: mutypes.SET_TOURNAMENT_CURRENT_RELATED_TOURNAMENTS,
            setTournamentPlaces: mutypes.SET_TOURNAMENT_PLACES,
            setTournamentPrizes: mutypes.SET_TOURNAMENT_PRIZES,
            setIsHashTournament: mutypes.SET_IS_HASH_TOURNAMENT,
            setTournamentMetaTitle: mutypes.SET_TOURNAMENT_META_TITLE,
            setTournamentMetaDescription: mutypes.SET_TOURNAMENT_META_DESCRIPTION,
            setTournamentVariations: mutypes.SET_TOURNAMENT_VARIATIONS,
            setWithComment:mutypes.SET_WITH_COMMENTS
        }),

        ...mapActions([
            'storeTournament',
            'indexTournaments',
            'changeCurrentPage',
            'grabTournament',
            'updateTournament',
            'deleteTournament',
            'grabGames',
            'grabOrganizers',
            'indexFormat',
            'indexTimeZones',
            'getCountriesQuery',
            'calculateRatingByTournamentId',
            'grabDivisionsList',
            'addTournamentPlace',
            'changeTournamentPlace',
            'addTournamentPrize',
            'changeTournamentPrize',
            'generateTournament',
            'deleteTournamentPlace',
            'grabVariations'
        ]),
        async createTournament() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    Vue.toasted.error("Необходимо заполнить поля", {
                        position: 'top-right',
                        duration: 3000
                    })
                } else {
                    let selectedFeatureValue = []
                    this.selectedFeature.forEach((item) => {
                        selectedFeatureValue.push(
                            item.value
                        )
                    })

                    let selectedDivisionValue = []
                    this.selectedDivision.forEach((item) => {
                        if (item.value === 0) {
                            selectedDivisionValue = this.divisions.filter((division) => {
                                return division.value !== 0;
                            }).map((division) => {
                                return division.value;
                            })
                        } else {
                            selectedDivisionValue.push(
                                item.value
                            )
                        }
                    })

          let transformTournIdsToArray = [];
          if (this.selectedTournamentSeries) {
            transformTournIdsToArray = Array.from(
              this.selectedTournamentSeries.split(",")
            );
          }
          const formData = new FormData();
          formData.append("game_id", this.gameId);
          formData.append("variation_id", this.newVariation);
          formData.append("hash", this.isHashTournament ? 1 : 0);
          formData.append("with_comments", this.withComments ? 1 : 0);
          formData.append("organizer_id", this.organizerId);
          formData.append("end_reg_time", this.newEndReg + ":00");
          formData.append("start_reg_time", this.newStartReg + ":00");
          formData.append("start_time", this.newStartTournament + ":00");
          formData.append("name", this.newTournamentName);
          for (const [key, value] of Object.entries(this.newDescription)) {
            formData.append(`description[${key}]`, value);
          }
          for (const [key, value] of Object.entries(this.newRules)) {
            formData.append(`rules[${key}]`, value);
          }
          for (const [key, value] of Object.entries(this.newSchedule)) {
            formData.append(`schedule[${key}]`, value);
          }
          formData.append("prize_fond", this.newPrizeFond);
          formData.append("format_id", this.newFormats);
          formData.append("timezone", this.newTimezone);
          formData.append("slot_count", +this.newSlotCount);

          // метаданные
          for (const [key, value] of Object.entries(this.newMetaTitle)) {
            formData.append(`meta_title[${key}]`, value);
          }

          for (const [key, value] of Object.entries(this.newMetaDescription)) {
            formData.append(`meta_description[${key}]`, value);
          }

                    if (this.newImage) {
                        formData.append('logo', this.newImage)
                    }
                    if (this.newYoutubeLink) {
                        formData.append("youtube_link", this.newYoutubeLink)
                    }
                    if (this.newCountry) {
                        formData.append('country_id', this.newCountry)
                    }
                    if (selectedFeatureValue.length > 0) {
                        Object.entries(selectedFeatureValue).forEach(([key, value]) => {
                            formData.append(`features_ids[]`, value);
                        });
                    }
                    for (const [key, value] of Object.entries(selectedDivisionValue)) {
                        formData.append(`divisions[]`, value)
                    }
                    if (this.selectedTournamentSeries) {
                        for (const [key, value] of Object.entries(transformTournIdsToArray)) {
                            formData.append("related_tournament_ids[]", value)
                        }
                    }
                    if (this.tournamentPlaces.length) {
                        formData.append("tournament_place_points", JSON.stringify(this.tournamentPlaces));
                    }
                    if (this.tournamentPrizes.length) {
                        formData.append("prize_fund", JSON.stringify(this.tournamentPrizes));
                    }
                    this.storeTournament(formData).then(() => this.$router.push({path: 'tournaments'})).catch((err) => {
                        err.response.data.errors.tournament_place_points && err.response.data.errors.tournament_place_points.forEach(() =>
                            Vue.toasted.error("Необходимо заполнить place pts", {
                                position: 'top-right',
                                duration: 3000
                            }))
                    })
                }
            });
        },
        updateCurrentTournament() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    Vue.toasted.error("Необходимо заполнить поля", {
                        position: 'top-right',
                        duration: 3000
                    })
                } else {
                    const selectedFeature = this.currentFeature.map((el) => {
                        return el.value
                    })
                    let selectedDivisionValue = []
                    this.currentDivisions.forEach((item) => {
                        if (item.value === 0) {
                            selectedDivisionValue = this.divisions.filter((division) => {
                                return division.value !== 0;
                            }).map((division) => {
                                return division.value;
                            })
                        } else {
                            selectedDivisionValue.push(item.value)
                        }
                    })
                    this.updateTournament({
                        id: this.$route.params.id,
                        // formats: this.currentFormats.value,
                        ...(this.newImage && {logo: this.newImage}),
                        features: selectedFeature,
                        divisions: selectedDivisionValue,
                        isHashTournament: this.isHashTournament,
                        variation_id: this.newVariation
                    }).then(() => {
                        this.$router.go(-1)
                    })
                }})
        },

        calculateTeamsRatingForTournament(tournamentId) {
            this.calculateRatingByTournamentId(tournamentId).then((res) => {
                Vue.toasted.success("Успешно запущено начисление рейтинга для турнира", {
                    position: 'top-center',
                    duration: 3000
                });
            }).catch(() => {
                Vue.toasted.error("не удалось запустить (=", {
                    position: 'top-center',
                    duration: 3000
                });
            })
        },
        generateNewTournament() {
            this.generateTournament().then(() => {
                Vue.toasted.success("Успешно создан турнир", {
                    position: 'top-center',
                    duration: 3000
                });
                this.grabTournament(this.$route.params.id)
            }).catch(() => {
                Vue.toasted.error("Не удалось сгенерерировать турнир (=", {
                    position: 'top-center',
                    duration: 3000
                });
            })
        },
        async getPlacePtsFromGroupResults() {
            await this.grabStandingTournament(this.$route.params.id)
            this.ptsFromGroupTeamsList = this.groupTeams ? this.groupTeams.filter((pts) => pts.result_fields.place_pts > 0) : []
        },
        deleteTournamentPlace({commit, getters}) {
            if (this.ptsFromGroupTeamsList.length > 0) {
                this.$toasted.error('Нельзя удалить place pts, т.к. он уже используется', {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
            } else {
                const tournamentPlaces = this.tournamentPlaces
                tournamentPlaces.pop()
            }
        },
        async getTournamentsByGame(game) {
            this.isLoadingTournaments = true
            await this.indexTournaments({
                ...(game && {gameId: game}),
                ...(this.searchForTournamentsModel && {search: this.searchForTournamentsModel}),
            })
                .then(() => {
                    this.selectedGameId = game || null
                    this.isLoadingTournaments = false
                })
        },
        async getTournamentsByVariation(variation_id) {
          this.isLoadingTournaments = true;
          await this.indexTournaments({
            ...(variation_id && { variation_id: variation_id }),
          }).then(() => {
            this.isLoadingTournaments = false;
          });
        },
        async getFormatsByGameId() {
            Promise.all([
                await this.indexFormat({
                    gameId: this.gameId
                }),
                await this.grabDivisionsList({
                    ...(this.gameId === 1 && {slug: 'pubg_mobile'}),
                    ...(this.gameId === 3 && {slug: 'mlbb'}),
                })
            ])
        },
        async loadVariations() {
            await this.grabVariations();
        }
    },
    //===============
    // methods end
    //===============
}
