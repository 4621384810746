import adminHttp from "@/api/adminHttp";

const END_POINT = '/api';
const CLIENT_END_POINT = '/api';

export default {

    index: (region, params = {}) => {
        return adminHttp.get(`${END_POINT}/v1/admin/world-team-rating/${region}`, {
            params, // Передаём параметры в запрос
        })
    },

    regions: () => adminHttp.get(END_POINT + `/v1/client/world-rating-regions`, {}),

    actualize: () => adminHttp.get(END_POINT + `/admin/rating/actualize-pubg-mobile-world-team-rating`),
}