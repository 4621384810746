<template>
  <div class="card p-0">
    <b-card-body class="mb-1 row justify-content-center justify-content-md-between py-0 px-4">
      <div>
        <h5 class="mb-0 mr-1 mt-2">
          <button v-b-toggle.primary_icon_open_close_1 class="btn btn-light">Входные данные<i
              class="fa fa-chevron-down ml-2"></i></button>
        </h5>
      </div>

      <div>
        <h5 class="mt-2 mb-2 mr-1">
          <button
              @click="grabStandingTournamentTeamSlots({
                groupId:selectedGroupId,
                stageId:selectedStageId
              })"
              v-b-toggle.primary_icon_open_close_2 class="btn btn-light">Данные по слоту<i
              class="fa fa-chevron-down ml-2"></i></button>
        </h5>
      </div>

      <div class="mt-2">
        <b-input-group prepend="Поиск">
          <b-input placeholder="команда/nick/ID" v-model="searchTeam"></b-input>
        </b-input-group>
      </div>
    </b-card-body>
    
    <b-collapse id="primary_icon_open_close_1" accordion="my-accordion-icon-open-close" role="tabpanel">
      <div class="pl-4 pr-4 mt-2">
        <InDataStandingsTournament/>
      </div>


    </b-collapse>

    <b-collapse id="primary_icon_open_close_2" accordion="my-accordion-icon-open-close" role="tabpanel">
      <div class="pl-4 pr-4 mt-2">
        <SlotsStandingsTournament/>
      </div>

    </b-collapse>
  </div>
</template>

<script>
import SlotsStandingsTournament from "@/pages/Tournament/StandingsTournament/SlotsStandingsTournament.vue";
import InDataStandingsTournament from "@/pages/Tournament/StandingsTournament/InDataStandingsTournament.vue";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";

export default {
  name: 'InfoStandingsTournament',
  components: {InDataStandingsTournament, SlotsStandingsTournament},
  mixins: [TournamentStandingsAdapterMixin],
}
</script>