import api from '/src/api/api';
import * as mutypes from "/src/store/mutation.types"

export default {
    state: () => {
        return {
            teamsRating: [],
            pagination:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            paginationForTeamRatings:{
                by:10,
                currentPage:1,
                totalPages:1,
            },
            showTeamRatings: [],
            selectedDivisionSlug: [],
            divisions: [],
        }
    },
    mutations: {
        [mutypes.SET_TEAMS_RATING]: (state, value) => state.teamsRating = value,
        [mutypes.SET_TEAMS_RATING_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TEAMS_RATING_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_TEAM_RATINGS_SHOW]: (state, value) => state.showTeamRatings = value,
        [mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_TOTAL]: (state, value) => state.paginationForTeamRatings.totalPages = value,
        [mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_CURRENT_PAGE]: (state, value) => state.paginationForTeamRatings.currentPage = value,
        [mutypes.SET_SELECTED_DIVISION]: (state, value) => state.selectedDivisionSlug = value,
    },

    getters: {
        getTeamsRating                           : state => state.teamsRating,
        getTeamsRatingPaginationTotal            : state => state.pagination.totalPages,
        getTeamsRatingPaginationCurrentPage      : state => state.pagination.currentPage,
        getTeamRatingsShow                       : state => state.showTeamRatings,
        getTeamRatingsShowPaginationTotal        : state => state.paginationForTeamRatings.totalPages,
        getTeamRatingsShowPaginationCurrentPage  : state => state.paginationForTeamRatings.currentPage,
        getSelectedDivision                      : state => state.selectedDivisionSlug,
    },

    actions: {
        grabTeamsRating: async ({commit, getters}, params) => {
            let response = await api.rating.index({
                page: params.pageNumber,
                "search":params.search,
                "filter[division.slug]":params.division,
            });
            commit(mutypes.SET_TEAMS_RATING, response.data?.teams);
            commit(mutypes.SET_SELECTED_DIVISION, response.data?.teams[0]?.division?.slug);
            commit(mutypes.SET_TEAMS_RATING_PAGINATION_TOTAL, response.data?.pagination?.total);
            commit(mutypes.SET_TEAMS_RATING_PAGINATION_CURRENT_PAGE, params?.pageNumber);

            return response.status;
        },
        async showTeamRatings({commit, getters}, params) {
            let response = await api.rating.show(params.id, {
                page: params.pageNumber,
                "search": params.search
            })
            commit(mutypes.SET_TEAM_RATINGS_SHOW, response.data.tournaments || [])
            commit(mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TEAM_RATINGS_SHOW_PAGINATION_CURRENT_PAGE, params.pageNumber);
            return response.status;
        },
    }
}