<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Команды" title="Список команд"/>
      <b-card>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <SearchInput 
              v-model="searchForTeamsModel" 
              @search="getFilteredTeams()" 
              :placeholder-name="'Поиск команд'"
              class="mb-2 mb-md-0 w-100 w-md-auto"
            />
            <b-dropdown 
              variant="outline-primary" 
              id="dropdown-team-type" 
              :text="teamTypeText" 
              class="ml-md-2 w-100 w-md-auto"
            >
              <b-dropdown-item @click="setTeamTypeFilter('all')" :active="teamTypeFilter === 'all'">Все</b-dropdown-item>
              <b-dropdown-item @click="setTeamTypeFilter('pro')" :active="teamTypeFilter === 'pro'">PRO Команды</b-dropdown-item>
            </b-dropdown>
          </div>
          <b-dropdown variant="primary" id="dropdown-1" text="Дисциплина" class="mt-2">
            <b-dropdown-item @click="getTeamsByGame()">
              <div class="text-dark">Все</div>
            </b-dropdown-item>
            <b-dropdown-item
                v-for="(game, index) in games" :key="index + 'game-' + game.value"
                @click="getTeamsByGame(game.value)"
                variant="primary"
            >
              <div class="text-dark">
                {{ game.text }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="notification-for-search mb-4">Поиск по id, названию команды, почте и username капитана</div>
        <div v-if="isLoadingTeams" class="loading-wrapper">
           <b-spinner class="spinner"></b-spinner>
        </div>
        <b-table
            v-else
            responsive
            ref="table"
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :items="teams"
            :fields="fields"
            :tbody-tr-class="trClass"
            :current-page=0
            :per-page=10
            class="mb-5"
        >
          <template #cell(team)="data">
            <div>{{ data.item.name }}</div>
          </template>
          <template #cell(participants)="data">
            <div class="text-center">
              <div v-if="data.item.member_count">{{ data.item.member_count }}</div>
              <div v-else>0</div>
            </div>
          </template>
          <template #cell(last_tournament)="data">
            <div v-if="data.item.lastTournament">
              {{ new Date(data.item.lastTournament.created_at).toLocaleString().slice(0, -3) }}
            </div>
            <div v-else></div>
          </template>
          <template #cell(tournament_count)="data">
            <div class="text-center">
              <div v-if="data.item.tournaments_count">
                {{ data.item.tournaments_count }}
              </div>
              <div v-else>0</div>
            </div>
          </template>
          <template #cell(game_title)="data">
            <div>{{ data.item.game.name }}</div>
          </template>
          <template #cell(created_date)="data">
            <div>{{ moment(data.item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') }}</div>
          </template>
          <template #cell(deleted_date)="data">
            <div>{{ moment(data.item.deleted_at, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm') }}</div>
          </template>
          <template #cell(team_owner)="data">
            <div>{{ data.item.teamOwner.email }}</div>
          </template>
          <template #cell(action)="data">
            <div class="row justify-content-center">
              <b-btn variant="primary" class="mr-1 p-1" @click="showTeamInfo(data.item.id)">
                <i class="fa fa-eye"></i></b-btn>
              <template>
                <div v-if="data.item.currentBans && data.item.currentBans.length">
                  <div v-for="bans in data.item.currentBans" :key="bans.id">
                    <b-btn variant="warning p-1" @click="deleteTeamBan({teamId: bans.id})">
                      <i class="fa fa-ban"></i>
                    </b-btn>
                  </div>
                </div>
                <div v-else>
                  <b-btn variant="danger p-1" @click="showTeamBanModal(data.item.id, data.item?.captain?.lang ? data.item?.captain?.lang?.code : 'Не указан')">
                    <i class="fa fa-ban"></i>
                  </b-btn>
                </div>
<!--                <b-btn variant="danger p-1" class="ml-1" @click="deleteButton(data.item.id)"><i-->
<!--                    class="fa fa-trash"></i></b-btn>-->
              </template>
            </div>
          </template>
        </b-table>
        <b-col md="6" class="p-0">
          <b-pagination
            @change="handlePageChange"
            v-model="currentPage"
            :total-rows="total"
            :per-page="10"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-card>
    </div>
    <Modal name="BanTeam" title="Бан команды" v-model="isShowBanTeamModal" :params="{ id: selectedTeamId, lang: selectedCaptainLang }"/>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TeamBanAdapterMixin} from "@/mixins/TeamBan/TeamBanAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import SearchInput from "@/components/SearchInput.vue";
import Modal from '@/components/modals/index.vue'

export default {
  name: 'TeamsList',
  components: {SearchInput, Modal},
  mixins: [TeamsAdapterMixin, ModalAdapterMixin, TeamBanAdapterMixin, PaginationHelperMixin],
  mounted() {
    (async () => {
      this.recordPageToStorage(this.currentPage, this.pageKey)
      await this.indexTeams({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
        teamType: this.teamTypeFilter
      })
      await this.grabGamesForTeams()
      await this.getGameIds()
    })()
  },
  data() {
    return {
      moment,
      pageKey: 'pageNumberTeamList',
      fields: [
        {key: 'id', label: '№', sortable: true},
        {key: 'team', label: 'Команды', sortable: true},
        {key: 'participants', label: 'Участники (всего/игр)', sortable: true},
        {key: 'last_tournament', label: 'Дата последнего турнира', sortable: true},
        {key: 'tournament_count', label: 'Количество турниров', sortable: true},
        {key: 'game_title', label: 'Название игры', sortable: true},
        {key: 'created_date', label: 'Дата создания', sortable: true},
        {key: 'action', label: 'Инструменты', sortable: false},
      ],
      striped: true,
      bordered: true,
      gameIds: [],
      isShowBanTeamModal: false,
      selectedTeamId: null,
      selectedCaptainLang: null,
      teamTypeFilter: 'all'
    }
  },
  methods: {
    setTeamTypeFilter(type) {
      this.teamTypeFilter = type;
      this.currentPage = 1;
      this.getFilteredTeams();
    },
    getGameIds() {
      this.gameIds = this.games.map((game) => game.value)
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey);
      this.currentPage = pageNumber;
      this.getFilteredTeams();
    },
    async deleteButton(id) {
      this.optionName('Вы действительно хотите удалить эту команду?')
      this.idForDeleting(id)
      this.openModal()
    },
    getFilteredTeams() {
      this.isLoadingTeams = true;
      this.indexTeams({
        pageNumber: this.currentPage,
        search: this.searchForTeamsModel,
        ...(this.gameIds.includes(this.selectedGameId) && { gameId: this.selectedGameId }),
        ...(this.teamTypeFilter === 'pro' && { divisionId: 5 })
      })
      .then(() => {
        this.isLoadingTeams = false;
      });
    },
    trClass: rowData => rowData?.currentBans && rowData?.currentBans.length && 'table-dark',
    showTeamBanModal(teamId, captainLang) {
      this.selectedTeamId = teamId
      this.selectedCaptainLang = captainLang
      this.isShowBanTeamModal = true
    }
  },
  computed: {
    filteredTeams() {
      if (this.teamTypeFilter === 'pro') {
        return this.teams.filter(team => team.division?.id === 5);
      }
      return this.teams;
    },
    teamTypeText() {
      return this.teamTypeFilter === 'pro' ? 'PRO Команды' : 'Все';
    }
  },
}
</script>