import * as mutypes from "@/store/mutation.types";
import api from '/src/api/api';

export default {
    namespaced: true,
    state: () => ({
        votes: [],
        currentVote: null,
        pagination: {
            by: 10,
            currentPage: 1,
            totalPages: 1,
        },
    }),

    mutations: {
        [mutypes.SET_VOTES]: (state, votes) => state.votes = votes,
        [mutypes.SET_CURRENT_VOTE]: (state, vote) => state.currentVote = vote,
        [mutypes.SET_VOTES_PAGINATION_TOTAL]: (state, total) => state.pagination.totalPages = total,
        [mutypes.SET_VOTES_PAGINATION_CURRENT_PAGE]: (state, page) => state.pagination.currentPage = page,
    },

    actions: {
        async fetchVotes({ commit }, params = {}) {
            try {
                const response = await api.votes.index(params);
                commit(mutypes.SET_VOTES, response.data.data);
                commit(mutypes.SET_VOTES_PAGINATION_TOTAL, response.data.pagination.total);
                commit(mutypes.SET_VOTES_PAGINATION_CURRENT_PAGE, params.page || 1);
                return response;
            } catch (error) {
                console.error("Error fetching votes:", error);
                throw error;
            }
        },

        async createVote({ commit }, voteData) {
            try {
                const response = api.votes.store(voteData);
                return response;
            } catch (error) {
                console.error("Error creating vote:", error);
                throw error;
            }
        },

        async updateVote({ commit }, { id, voteData }) {
            try {
                const response = await api.votes.update(id, voteData);
                return response;
            } catch (error) {
                console.error("Error updating vote:", error);
                throw error;
            }
        },

        async deleteVote({ commit, dispatch }, id) {
            try {
                const response = await api.votes.delete(id);
                await dispatch('fetchVotes');
                return response;
            } catch (error) {
                console.error("Error deleting vote:", error);
                throw error;
            }
        },

        async fetchVote({ commit }, id) {
            try {
                const response = await api.votes.show(id);
                commit(mutypes.SET_CURRENT_VOTE, response.data.data); // Set the entire response.data
                return response;
            } catch (error) {
                console.error("Error fetching vote:", error);
                throw error;
            }
        },


        async finishVote({ commit }, id) {
            try {
                const response = await api.votes.finish(id);
                return response;
            } catch (error) {
                console.error("Error finishing vote:", error);
                throw error;
            }
        },
    },

    getters: {
        getVotes: state => state.votes,
        getCurrentVote: state => state.currentVote,
        getVotesPaginationTotal: state => state.pagination.totalPages,
        getVotesPaginationCurrentPage: state => state.pagination.currentPage,
    },
};