import * as mutypes from "@/store/mutation.types";
import api from "@/api/api";

export default {
    state: () => {
        return {
            teams: {
                id: null,
                name: '',
                game_id: null,
            },
            pagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            showTeams: [],
            members: [],
            currentDivision: null,
            teamRatings: null,
            teamLogs: [],
            captainLang: '',
            games: null,
            teamLogsPagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            deletedTeamsPagination: {
                by: 10,
                currentPage: 1,
                totalPages: 1,
            },
            deletedTeams: []
        }
    },
    mutations: {
        [mutypes.SET_TEAMS]: (state, value) => state.teams = value,
        [mutypes.SET_TEAMS_ID]: (state, value) => state.teams.id = value,
        [mutypes.SET_TEAMS_NAME]: (state, value) => state.teams.name = value,
        [mutypes.SET_TEAMS_GAME_ID]: (state, value) => state.teams.name = value,
        [mutypes.SET_TEAMS_SHOW]: (state, value) => state.showTeams = value,
        [mutypes.SET_TEAMS_PAGINATION]: (state, value) => state.pagination = value,
        [mutypes.SET_TEAMS_PAGINATION_TOTAL]: (state, value) => state.pagination.totalPages = value,
        [mutypes.SET_TEAMS_PAGINATION_CURRENT_PAGE]: (state, value) => state.pagination.currentPage = value,
        [mutypes.SET_TEAMS_MEMBERS]: (state, value) => state.members = value,
        [mutypes.SET_TEAMS_CURRENT_DIVISION]: (state, value) => state.currentDivision = value,
        [mutypes.SET_TEAMS_TEAM_RATING]: (state, value) => state.teamRatings = value,
        [mutypes.SET_TEAM_LOGS]: (state, value) => state.teamLogs = value,
        [mutypes.SET_TEAM_LOGS_PAGINATION]: (state, value) => state.teamLogsPagination = value,
        [mutypes.SET_TEAM_LOGS_PAGINATION_TOTAL]: (state, value) => state.teamLogsPagination.totalPages = value,
        [mutypes.SET_TEAM_LOGS_PAGINATION_CURRENT_PAGE]: (state, value) => state.teamLogsPagination.currentPage = value,
        [mutypes.SET_TEAMS_CAPTAIN_LANG]: (state, value) => state.captainLang = value,
        [mutypes.SET_TEAMS_GAMES]: (state, value) => state.games = value,
        [mutypes.SET_DELETED_TEAMS]: (state, value) => state.deletedTeams = value,
        [mutypes.SET_DELETED_TEAMS_PAGINATION]: (state, value) => state.deletedTeamsPagination = value,
        [mutypes.SET_DELETED_TEAMS_PAGINATION_TOTAL]: (state, value) => state.deletedTeamsPagination.totalPages = value,
        [mutypes.SET_DELETED_TEAMS_PAGINATION_CURRENT_PAGE]: (state, value) => state.deletedTeamsPagination.currentPage = value,
    },
    getters: {
        getTeams: state => state.teams,
        getTeamsId: state => state.teams.id,
        getTeamsName: state => state.teams.name,
        getTeamsGameId: state => state.teams.game_id,
        getTeamsShow: state => state.showTeams,
        getTeamsPaginationTotal: state => state.pagination.totalPages,
        getTeamsPaginationCurrentPage: state => state.pagination.currentPage,
        getMembers: state => state.members,
        getCurrentDivision: state => state.currentDivision,
        getTeamCurrentRating: state => state.teamRatings,
        getTeamLogs: state => state.teamLogs,
        getTeamLogsPaginationTotal: state => state.teamLogsPagination.totalPages,
        getTeamLogsPaginationCurrentPage: state => state.teamLogsPagination.currentPage,
        getCaptainLang: state => state.captainLang,
        getGamesForTeams: state => state.games,
        getDeletedTeams: state => state.deletedTeams,
        getDeletedTeamsPaginationTotal: state => state.deletedTeamsPagination.totalPages,
        getDeletedTeamsPaginationCurrentPage: state => state.deletedTeamsPagination.currentPage,
    },
    actions: {
        async indexTeams({commit}, params) {
            // alert(params.pageNumber)
            let response = await api.teams.index({
                page: params.pageNumber,
                "filter[type]": params.type,
                "filter[stage.id]": params.stageId,
                "tournament_id": params.tournament_id,
                "filter[game_id]": params.gameId,
                "filter[division_id]": params.divisionId,
                "search": params.search,
            })
            let responseTeams = response.data.teams;
            commit(mutypes.SET_TEAMS, responseTeams)
            commit(mutypes.SET_TEAMS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TEAMS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async trashedTeams({commit}, params) {
            let response = await api.teams.trashed({
                page: params.pageNumber,
                "filter[type]": params.type,
                "filter[stage.id]": params.stageId,
                "tournament_id": params.tournament_id,
                "filter[game_id]": params.gameId,
                "search": params.search
            })
            let responseTeams = response.data.teams;
            commit(mutypes.SET_DELETED_TEAMS, responseTeams)
            commit(mutypes.SET_DELETED_TEAMS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_DELETED_TEAMS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        async showTeams({commit, getters}, id) {
            let response = await api.teams.show(id)
            commit(mutypes.SET_TEAMS_SHOW, response.data.team)
            commit(mutypes.SET_TEAMS_MEMBERS, response.data.team.members)

            let values = {};
            values = {
                value: response.data.team?.division?.id,
            }

            commit(mutypes.SET_TEAMS_CURRENT_DIVISION, values)
        },
        async deleteTeams({commit, getters}, id) {
            let response = await api.teams.delete(id)
            let team = getters.getTeams.filter(t => t.id !== id)

            await commit(mutypes.SET_TEAMS, team);
        },
        async storeTeam({commit, getters}, params) {
            return await api.teams.store(params);
        },
        async changeMemberRole({commit, getters, dispatch}, params) {
            api.teams.updateTeamMemberRole(params.teamId, params.userId, params.roleId)
                .then((res) => {
                    getters.getMembers.map(function (member) {
                        if (member.id === params.userId) {
                            member.role_in_team = res.data.team_member.role;
                        }
                        return member;
                    });
                    dispatch('showTeams', params.teamId);
                })
                .catch((res) => {
                    this._vm.$toast("не удалось изменить роль");
                });
        },
        async storeTeamMember({commit, getters}, params) {
            await api.teams.storeTeamMember(params.teamId, {members: [{id: params.userId, role: params.roleId}]})
                .then((res) => {
                    this._vm.$toast("Игрок добавлен");
                })
                .catch((res) => {
                    this._vm.$toast("не удалось добавить игрока");
                });
        },
        async updateTeamName({commit, getters}, params) {
            let filterdMembers = []
            getters.getMembers.forEach((e) => {
                filterdMembers.push({id: e.id, role: e.role_in_team.id})
            })

            await api.teams.update(params.teamId, {name: params.name, members: filterdMembers})
                .then(() => {
                    this._vm.$toast("Вы успешно поменяли название команды");
                })
                .catch(() => {
                    this._vm.$toast("Название команды уже занято");
                });
        },
        async showTeamRating({commmit, getters}, id) {
            const response = await api.rating.show(id)
            return response
        },
        async updateTeamRating({commit, getters}, params) {
            const response = await api.rating.update(params.id, {
                team_id: +params.team_id,
                points: +params.points,
                description: params.description
            })
            return response
        },
        async shortTeamRating({commit, getters}, id) {
            const response = await api.rating.short(id)
            commit(mutypes.SET_TEAMS_TEAM_RATING, response.data.team_ratings)
            return response
        },
        async addTeamRating({commit, getters}, params) {
            const response = await api.rating.store(params)
            return response
        },
        async teamLogsShow({commit, getters}, params) {
            let response = await api.teams.teamLogs({
                teamId: params.teamId,
                page: params.pageNumber,
            })
            commit(mutypes.SET_TEAM_LOGS, response.data.team_logs || [])
            commit(mutypes.SET_TEAM_LOGS_PAGINATION_TOTAL, response.data.pagination.total);
            commit(mutypes.SET_TEAM_LOGS_PAGINATION_CURRENT_PAGE, params.pageNumber);
        },
        grabGamesForTeams: async ({commit, getters}) => {
            await api.games.index().then(res => {
                const values = res.data.games.map((item) => ({
                        value: item.id,
                        text: item.name
                }))
                commit(mutypes.SET_TEAMS_GAMES, values);
            }).catch();
        },
    }
}
