<template>
  <div>
    <div class="d-flex" v-if="data && data.length">
      <b-btn
          class="mr-3"
          variant="primary"
          v-for="item in data"
          :key="item.id"
          :class="{ active: $route.params.region === item.id }"
          @click="setPath(item.id)"
      >
        {{ item.name }}
      </b-btn>
    </div>
    <p v-else>Loading...</p>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    async setPath(regionId) {
      // Проверяем, отличается ли текущий регион от целевого
      if (this.$route.params.region === regionId) return;

      try {
        // Навигация с очисткой всех query параметров
        await this.$router.push({
          name: 'world_rating', // Имя маршрута
          params: { region: regionId }, // Новый регион
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    if (this.data && this.data.length) {
      const currentRegion = this.$route.params.region || this.data[0].id;

      // Если region отсутствует или не совпадает, перенаправляем с очисткой query
      if (!this.$route.params.region || this.$route.params.region !== currentRegion) {
        this.$router.replace({
          name: 'world_rating',
          params: { region: currentRegion },
        });
      }
    }
  },
};
</script>




